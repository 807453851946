import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { AUTH_BASE_URL, getToken} from "@/utils";


const BASE_URL = "http://localhost:80"

export const questionsApi = createApi({
  	reducerPath: "novaApi",
	refetchOnFocus: true,
	baseQuery: fetchBaseQuery({
		baseUrl: `${BASE_URL}`,
    // prepareHeaders: async (headers) => {
    //   const token = getToken();
    //   if (token) {
    //     headers.set("Authorization", `Bearer ${token}`);
    //   }
    //   return headers;
    // },
	}),
	endpoints: (builder) => ({
		//define endpoints here e.g
    questions: builder.mutation({
      query: (data) => ({
        url: "/interview-questions",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
	}),
});

// custom hook
export const {
  useQuestionsMutation,
} = questionsApi;
