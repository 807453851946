import { Player } from "@lottiefiles/react-lottie-player";
import { motion } from "framer-motion";
import { ChangeEvent, FormEvent, useReducer } from "react";
import styled from "styled-components";
import { addUserData } from "../../supabase";
import CloseIcon from "../CloseIcon";

//reducers
const reducer = (state: any, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "email":
      return { ...state, email: { ...state.email, value: payload } };
    case "feedback":
      return { ...state, feedback: { ...state.feedback, value: payload } };
    case "defaultemail":
      return { ...state, email: { submitted: true, value: "" } };
    case "defaultfeedback":
      return { ...state, feedback: { submitted: true, value: "" } };
  }
};

//animation variables
const dropIn = {
  hidden: {
    y: "-10vh",
    opacity: 0,
  },
  visible: {
    y: "0vh",
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
};

const NewsLetter = ({ toggleModal }: { toggleModal: () => void }) => {
  const initialState = {
    email: {
      submitted: false,
      value: "",
    },
    feedback: {
      submitted: false,
      value: "",
    },
  };

  const [inputState, dispatch] = useReducer(reducer, initialState);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatch({
      type: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    type: "EmailNewsLetter" | "defaultemail" | "Feedback"
  ) => {
    e.preventDefault();
    let inputdata, defaultName;
    if (type === "EmailNewsLetter") {
      inputdata = {
        email: inputState.email.value,
      };
      defaultName = "defaultemail";
    } else if (type === "Feedback") {
      inputdata = {
        feedback: inputState.feedback.value,
      };
      defaultName = "defaultfeedback";
    }

    await addUserData(type, inputdata);
    dispatch({ type: defaultName });
  };

  return (
    <Wrapper
      variants={dropIn}
      initial="hidden"
      animate="visible"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ModalHeader>
        <CloseIcon toggle={toggleModal} />
        <h2> Hi there, Welcome to InstantApply </h2>
      </ModalHeader>
      <ModalBottomWrapper>
        {/* <FormTitle>Leave a Feedback</FormTitle> */}
        <Description>
          What you think we need to improve on or include? Please drop a
          feedback
        </Description>
        <form onSubmit={(e) => handleSubmit(e, "Feedback")}>
          <div className="form-element-group">
            <textarea
              className="form-element"
              placeholder="thoughts..."
              name="feedback"
              autoComplete="off"
              value={inputState.feedback.value}
              onChange={handleInputChange}
              required={true}
            />
          </div>
          <button className="w-full py-4 text-lg text-white rounded-lg my-4 bg-blue-500">
            {inputState.feedback.submitted ? (
              <div className="wrap">
                {" "}
                Submitted{" "}
                <Player
                  src="/assets/lottie/sent.json"
                  className="lottie"
                  autoplay
                />
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </ModalBottomWrapper>
    </Wrapper>
  );
};

export const ModalBottom = styled.div`
  padding: 0 2em;
  .form-element-group {
    width: 100%;
    margin: auto;
    border: 1.5px solid #ccc;
  }
  .form-element-group:first-child {
    border-radius: 0.3em 0.3em 0 0;
    border-bottom: none;
  }
  .form-element-group:last-child {
    border-radius: 0 0 0.3em 0.3em;
  }
  .form-element {
    width: 100%;
    padding: 1.5em;
    border: none;
    ::placeholder {
      font-size: 16px;
    }
    :focus {
      outline-color: black;
    }
  }
`;
export const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  margin: 1em 0;
  @media (min-width: 760px) {
    margin: 1.5em 0;
  }
`;
export const Wrapper = styled(motion.div)`
  background-color: white;
  /* height: 50vh; */
  padding: 0.5em;
  width: 90%;
  max-width: 600px;
  border-radius: 0.5em;
  @media (min-width: 760px) {
    border-radius: 1em;
  }
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 1em;
  h2 {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    width: 80%;
    color: #555;
  }
`;

const ModalBottomWrapper = styled(ModalBottom)`
  .form-element-group {
    border: none;
    border-radius: 0px;
    margin: 1.5em 0 0.5em;
  }
  .form-element {
    border: 1px solid #ccc;
    border-radius: 0.5em;
  }
  .rule {
    border: 0.5px solid #eee;
    position: relative;
  }
  .form__button {
    position: relative;
  }

  .lottie {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
  }
  .lottie--top {
    transform: translateY(-10%);
  }

  .wrap {
    display: flex;
    justify-content: center;
  }
`;

const FormTitle = styled(Title)`
  margin: 1.5em 0 0.5em 0;
`;
const Description = styled.p`
  font-size: 16px;
  color: #777;
  margin: 0;
`;

export default NewsLetter;
