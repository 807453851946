import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CounterState {
  questionState: []|null;
}

const initialState: CounterState = {
  questionState:[]
};

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setQuestion: (state, action: PayloadAction<[] | null>) => {
      state.questionState = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setQuestion } = questionSlice.actions;

export default questionSlice.reducer;
