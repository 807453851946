import { PopContext } from "@/context/PopContext";
import { useContext } from "react";
import styled from "styled-components";

import { RiFeedbackLine } from "react-icons/ri";
import Modal from "../modal";
import NewsLetter from "../Newsletter";
import { motion } from "framer-motion";

const Feedback = () => {
  const { openNewsLetter, setOpenNewsLetter } = useContext(PopContext);
  return (
    <>
      {/* <FeedbackButton onClick={() => setOpenNewsLetter(!openNewsLetter)}>
        <div>
          <span className="hidden md:block">Leave a feedback? </span>
          <span>
            <RiFeedbackLine className="w-6 h-6  md:h-4 md:w-4" />{" "}
          </span>
        </div>
      </FeedbackButton> */}

      {openNewsLetter && (
        <Modal toggleModal={() => setOpenNewsLetter(!openNewsLetter)}>
          <NewsLetter toggleModal={() => setOpenNewsLetter(!openNewsLetter)} />
        </Modal>
      )}
    </>
  );
};

const FeedbackButton = styled(motion.button)`
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  box-shadow: 0.1px 0.1px 2px rgba(0, 0, 0, 0.5);
  background-color: #0f83f6;
  padding: 0.5em 1.5em;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 2%;
  /* transform: translateX(-50%); */
  /* transform-origin: bottom left; */

  z-index: 1000000;
  border-radius: 50px;
  transition: all 0.2s ease;
  /* display: none; */
  :hover {
    box-shadow: 0.2px 0.2px 10px rgba(0, 0, 0, 0.4);
    /* transform: translateX(-50%) translateY(-3px); */
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;

    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.76, 0.02, 0.23, 0.96);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: pulse;

    @keyframes pulse {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.1);
      }
    }
  }
  span:nth-last-child(1) {
    margin-left: 0.3em;
  }
`;

export default Feedback;
