import useProfile from "@/hooks/useProfile";
import { ReactNode, createContext, useEffect, useState } from "react";

export const CreditContext = createContext({ credits: 0, coins: 0 });

export default function CreditProvider({ children }: { children: ReactNode }) {
  const profile = useProfile();

  const [credits, setCredit] = useState({ credits: 0, coins: 0 });

  useEffect(() => {
    if (profile) {
      if (profile?.credits !== undefined)
        setCredit({ coins: profile?.emailcredits, credits: profile?.credits });
    }
  }, [profile]);

  return (
    <CreditContext.Provider value={credits}>{children}</CreditContext.Provider>
  );
}
