import {
  createBrowserSupabaseClient,
  createPagesBrowserClient,
} from "@supabase/auth-helpers-nextjs";
import { Session, SupabaseClient, createClient } from "@supabase/supabase-js";
import { removeAllCookies } from "./utils/remove";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL || "";
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY || "";
export const supabase = createPagesBrowserClient();

//Google Oauth
// export async function signInWithGoogle() {
// 	const { data, error } = await supabase.auth.signInWithOAuth({
// 		provider: "google",
// 		options: {
// 			redirectTo: "http://localhost:3000",
// 		},
// 	});
// 	return data;
// }

export const addUserData = async (type: any, formdata: any) => {
  const { error, data } = await supabase
    .from("feedback")
    .insert(formdata)
    .select();

  if (error) {
    console.log(error);
  }

  return data;
};

//supabase session
export async function getSession() {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  return session;
}

//send user profile details

export async function insertToProfile(formData: any, user: any) {
  if (user) {
    const { data, error } = await supabase
      .from("profile")
      .update(formData)
      .eq("email", user?.email)
      .select();
    return data;
  }
}

export async function getUserJobs(user: any) {
  if (!user) return;

  const { data, error } = await supabase
    .from("jobsSubmitted")
    .select("*")
    .order("created_at", { ascending: false })
    .eq("userID", user.id);

  if (error) console.log(error);

  return data;
}

export async function getUserJobsCount(user: any) {
  if (!user) return;

  const { count, error } = await supabase
    .from("jobsSubmitted")
    .select("*", { count: "exact", head: true })
    .eq("userID", user.id);

  if (error) console.log(error);

  return count;
}

export async function getUserProfile(user: any) {
  if (!user) return;
  const { data, error } = await supabase
    .from("profile")
    .select(
      "resume_url,filename,credits,event,onboarding,submitcount,id,emailcredits"
    )
    .eq("id", user.id);

  return data && data[0];
}
export async function updateProfile(user: any) {
  if (!user) return;
  const { data, error } = await supabase
    .from("profile")
    .select(
      "resume_email,resume_url,event,firstname,school,jobtitle,educationstartyear,educationstartmonth,educationendmonth,educationendyear,gmailContent,gmailSubject,lastname,github,portfolio,phone,linkedin,currentCompany,youlatino,educationdegree,educationdiscipline,gender,disabilitystatus,veteranstatus,pdfuploadtime"
    )
    .eq("id", user.id);

  return data && data[0];
}
export async function getUserOnboardingStatus(user: any) {
  if (!user) return;
  const { data, error } = await supabase
    .from("profile")
    .select("onboarding")
    .eq("id", user.id);

  return data;
}

//supabase signout
// export const signOut = async () => {
// 	// Use JS cookie to clear all supabase auth cookies

// 	// loop through and remove all cookies using js cookie
// 	await removeAllCookies();
// 	await supabaseClient.auth.signOut();

// 	// Use supabase client to clear local storage

// 	return window.location.reload();
// };

export async function getUserPdf(user_id: any) {
  if (!user_id) return;
  const { data, error } = await supabase
    .from("profile")
    .select("resume_url")
    .eq("id", user_id);

  return data;
}

export async function updateUserProfileInfo(user: any, country: string | null) {
  const { error } = await supabase
    .from("profile")
    .update({ user_country: country })
    .eq("id", user.id);
  if (error) {
    console.log(error);
  }
}

export async function updateJobStatus(jobID: string, status: string) {
  const { error } = await supabase
    .from("jobsSubmitted")
    .update({ status })
    .eq("id", jobID);
  if (error) {
    console.log(error);
  }
}

export async function getStatusInterviewingCount(user: any) {
  if (!user) return;
  const { data, error } = await supabase
    .from("jobstatuscoount")
    .select("total_occurrences")
    .eq("userID", user.id)
    .eq("status", "INTERVIEWING");
  return data;
}

export async function getStatusAppliedCount(user: any) {
  if (!user) return;
  const { data, error } = await supabase
    .from("jobstatuscoount")
    .select("total_occurrences")
    .eq("userID", user.id)
    .eq("status", "APPLIED");
  return data;
}

export async function getStatusAcceptedCount(user: any) {
  if (!user) return;
  const { data, error } = await supabase
    .from("jobstatuscoount")
    .select("total_occurrences")
    .eq("userID", user.id)
    .eq("status", "ACCEPTED");
  return data;
}

export async function getStatusRejectedCount(user: any) {
  if (!user) return;
  const { data, error } = await supabase
    .from("jobstatuscoount")
    .select("total_occurrences")
    .eq("userID", user.id)
    .eq("status", "REJECTED");
  return data;
}

// Function to query data by date range
export async function getDataByDateRange(
  startDate: any,
  endDate: any,
  user: any
) {
  try {
    if (!user) return;
    const { count, error } = await supabase
      .from("jobsSubmitted")
      .select("*", { count: "exact", head: true })
      .eq("userID", user.id)
      .gte("created_at", startDate.toISOString())
      .lte("created_at", endDate.toISOString());

    if (error) {
      console.error("Error fetching count:", error.message);
      return null;
    }

    return count;
  } catch (error: any) {
    console.error("Error:", error.message);
    return null;
  }
}

export const decreaseCreditCount = async (user: any) => {
  const { data, error } = await supabase.rpc("decreaseCredits", {
    user_id: user.id,
    decrease_num: 1,
  });

  if (error) throw error;
};
