import {
  getSession,
  getUserProfile,
  supabase,
  updateUserProfileInfo,
} from "@/supabase";
import { Session, UserAttributes } from "@supabase/supabase-js";
import axios from "axios";
import { useRouter } from "next/router";
import { ReactNode, createContext, useEffect, useState } from "react";

interface ProfileType {
  id: any;
  resume_url: string;
  credits?: number;
  filename: string;
  event: "subscription.create" | null;
  onboarding: number;
  submitcount: number;
  emailcredits: number;
}

interface UserAttributesWithIDType extends UserAttributes {
  id: string;
}

export const AuthContext = createContext<UserAttributesWithIDType | null>(null);
export const UserProfileContext = createContext<ProfileType | null>(null);
export const UserContext = createContext(null);
export const UserCountryContext = createContext<string | null>(null);

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<UserAttributesWithIDType | null>(null);
  const [country, setCountry] = useState<string | null>("Nigeria");
  const [profile, setProfile] = useState<ProfileType | null>(null);

  const router = useRouter();

  //get user's details
  useEffect(() => {
    async function getUser() {
      const session: Session | null = await getSession();
      if (session) {
        return setUser(session.user);
      }

      return setUser(null);
    }
    getUser();
  }, []);

  //fetch profile when user is updated
  useEffect(() => {
    if (!user) return;
    async function getActiveUserProfile() {
      const profile = await getUserProfile(user);
      if (profile) {
        return setProfile({
          filename: profile.filename,
          resume_url: profile.resume_url,
          credits: profile.credits,
          event: profile.event,
          onboarding: profile.onboarding,
          submitcount: profile.submitcount,
          id: profile.id,
          emailcredits: profile.emailcredits,
        });
      }
      return setProfile(null);
    }
    getActiveUserProfile();
  }, [user]);

  // listens to any chage to the profile table and adds it to the existing user profile info
  // useEffect(() => {
  //   if (!user) return;
  //   const channel = supabase
  //     .channel("schema-db-changes")
  //     .on(
  //       "postgres_changes",
  //       {
  //         event: "UPDATE",
  //         schema: "public",
  //         table: "profile",
  //         filter: `id=eq.${user.id}`,
  //       },
  //       (payload: { eventType?: any; new?: any }) => {
  //         if (payload.eventType === "UPDATE") {

  //           const {
  //             new: {
  //               filename,
  //               resume_url,
  //               event,
  //               credits,
  //               onboarding,
  //               submitcount,
  //               emailcredits,
  //               id,
  //             },
  //           } = payload;

  //           return setProfile({
  //             filename: filename,
  //             resume_url: resume_url,
  //             event: event,
  //             credits: credits,
  //             onboarding: onboarding,
  //             submitcount: submitcount,
  //             id: id,
  //             emailcredits
  //           });
  //         }
  //       }
  //     )
  //     .subscribe();
  //   return () => {
  //     channel.unsubscribe();
  //   };
  // }, [user]);

  useEffect(() => {
    const setUserCountry = async () => {
      try {
        const response = await axios("https://instantapply.co/api/getcountry");
        const { country } = response.data;
        if (country == "Nigeria") {
          setCountry("Nigeria");
        } else {
          setCountry(country);
        }
      } catch (error) {
        console.log("an error occurred while trying to retrieve country");
        setCountry("Nigeria");
      }
    };
    setUserCountry();
  }, []);

  useEffect(() => {
    async function authListener() {
      supabase.auth.onAuthStateChange((event: string, session: any) => {
        if (event == "SIGNED_OUT") {
          setUser(null);
          router.push("/");
        }
      });
    }
    authListener();
  }, []);

  useEffect(() => {
    const supabaseAuth = async () => {
      const session = await getSession();

      if (session) {
        await updateUserProfileInfo(session.user, country); //update the user profile document with the user's visiting country
        localStorage.setItem("session", JSON.stringify(session));
        return setUser(session.user);
      }
      return setUser(null);
    };
    supabaseAuth();
  }, [country]); //only update user to state when country has been fetched

  return (
    <AuthContext.Provider value={user}>
      <UserProfileContext.Provider value={profile}>
        <UserCountryContext.Provider value={country}>
          {children}
        </UserCountryContext.Provider>
      </UserProfileContext.Provider>
    </AuthContext.Provider>
  );
}
