 // store.ts

 import { configureStore } from "@reduxjs/toolkit";
 import { setupListeners } from "@reduxjs/toolkit/query";



 import { questionsApi } from "./services/questionService";


 import questionReducer from "./features/questionSlice";
 // Create the Redux store
 export const store = configureStore({
   reducer: {
    question: questionReducer,
    [questionsApi.reducerPath]: questionsApi.reducer,
   }, // Add your reducers here
   devTools: process.env.NODE_ENV !== "production",
   middleware: (getDefaultMiddleware) =>
   getDefaultMiddleware({
    serializableCheck: false,
   }).concat([
     questionsApi.middleware
   ]),

 });

 // Setup listeners for refetch behaviors
 setupListeners(store.dispatch);

 // Define RootState and AppDispatch types
 export type RootState = ReturnType<typeof store.getState>;
 export type AppDispatch = typeof store.dispatch;