import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

type popType = {
  openNewsLetter: boolean;
  setOpenNewsLetter: Dispatch<SetStateAction<boolean>>;
};

export const PopContext = createContext<popType>({
  openNewsLetter: false,
  setOpenNewsLetter: () => {},
});

export const PopContextProvider = ({ children }: { children: ReactNode }) => {
  const [openNewsLetter, setOpenNewsLetter] = useState(false);

  return (
    <PopContext.Provider
      value={{
        openNewsLetter,
        setOpenNewsLetter,
      }}
    >
      {children}
    </PopContext.Provider>
  );
};
